/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

/* eslint-disable react/jsx-filename-extension */

/* eslint-disable @typescript-eslint/no-floating-promises */
import { navigate } from 'gatsby';
import React, { useEffect } from 'react';

const NotFoundPage = () => {
  useEffect(() => {
    navigate('/'); // redirecting to home page
  }, []);

  return (
    <div>
      <h1>(404) NotFound Page</h1>
    </div>
  );
};

export default NotFoundPage;
